import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enLang from './en.json'
import arLang from './ar.json'

const resources = {
  en: {
    translation: enLang
  },
  ar: {
    translation: arLang
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;