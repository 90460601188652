import React from 'react'
function AccountPartial(props) {
  return (
    <div className='account-btn' onMouseOver={props.changeState}>
        <div className='name'>
            {props.name}
          </div>
          <div className='image'>
              <img src={require('../assets/images/default-profile.png')} alt='profile' />
          </div>
    </div>
  )
}

export default AccountPartial