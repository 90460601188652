import {lazy, Suspense}  from 'react'
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {UserDataProvider} from './components/UserDataProvider'
import './lang/i18n.js'
import { useTranslation } from 'react-i18next';
import ResourceDownloads from './pages/ResourceDownloads.js';
import Loading from './pages/Loading.js';
import { AuthProvider } from './components/AdminAuthProvider.js';
const Home = lazy(() => import('./pages/Home'))
const Products = lazy(() => import('./pages/Products'))
const ProductDetails = lazy(() => import('./pages/ProductDetails'))
const Services = lazy(() => import('./pages/Services'))
const Blog = lazy(() => import('./pages/Blog'))
const BlogDetails = lazy(() => import('./pages/BlogDetails'))
const Login = lazy(() => import('./pages/Login'))
const Signup = lazy(() => import('./pages/Signup'))
const Courses = lazy(() => import('./pages/Courses'))
const CourseDetails = lazy(() => import('./pages/CourseDetails'))
const MyCourses = lazy(() => import('./pages/MyCourses.js'))
const Error = lazy(() => import('./pages/error404.js'))
const PolaxisFilter = lazy(() => import('./pages/PolaxisFilter.js'))
const Cart = lazy(() => import('./pages/Cart.js'))
const FAQ = lazy(() => import('./pages/FAQ.js'))
const Contact = lazy(() => import('./pages/Contact.js'))
const Orders = lazy(() => import('./pages/Orders.js'))
const AddProduct = lazy(() => import('./pages/AddProduct.js'))
const AddAdminUser = lazy(() => import('./pages/AddAdminUser.js'))
const AdminUsers = lazy(() => import('./pages/AdminUsers.js'))
const ApplicationUsers = lazy(() => import('./pages/ApplicationUsers.js'))
const Logs = lazy(() => import('./pages/Logs.js'))
const AdminProducts = lazy(() => import('./pages/AdminProducts.js'))
function App() {
  const {i18n} = useTranslation()
  return (
    <Router>
      
      <UserDataProvider>
        <AuthProvider>
      <div className={`App ${i18n.language}`} dir={i18n.language === 'en' ? 'ltr' : 'rtl'}>
        <Navbar />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/courses' element={<Courses />} />
            <Route path='/courses/:course' element={<CourseDetails />} />
            <Route path='/polaxis-polarization-filter' element={<PolaxisFilter />} />
            <Route path='/services' element={<Services />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/:blog' element={<BlogDetails />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/products' element={<Products />} />
            <Route path='/products/:product' element={<ProductDetails />} />
            <Route path='/account/cart' element={<Cart />} />
            <Route path='/account/courses' element={<MyCourses />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/resource-downloads' element={<ResourceDownloads />} />
            <Route path='/loading' element={<Loading />} />




            <Route path='/admin/orders' element={<Orders />} />
            <Route path='/admin/add-products' element={<AddProduct />} />
            <Route path='/admin/products' element={<AdminProducts />} />
            <Route path='/admin/add-admin-user' element={<AddAdminUser />} />
            <Route path='/admin/admin-users' element={<AdminUsers />} />
            <Route path='/admin/application-users' element={<ApplicationUsers />} />
            <Route path='/admin/logs' element={<Logs />} />
            <Route path='*' element={<Error />} />
          </Routes>
          </Suspense>
        
        <Footer />
      </div>
      </AuthProvider>
      </UserDataProvider>
      
    </Router>
  );
}

export default App;
