import * as BsIcons from 'react-icons/bs'

const navData = [
    {
        icon:<BsIcons.BsHouse />,
        title:'home',
        path:"/"
    },
    {
        icon:<BsIcons.BsCollectionPlay />,
        title:"products",
        path:"/products",
        hasDropdown:true
    },
    {
        icon:<BsIcons.BsStar />,
        title:"services",
        path:"/services"
    },
    {
        icon:<BsIcons.BsJournalText />,
        title:"blog",
        path:"/blog"
    },
    {
        icon:<BsIcons.BsPhone />,
        title:"contact",
        path:"/contact"
    },
]

export default navData