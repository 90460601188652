import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [access, setAccess] = useState({
    isLocked:true,
    admin:{
      name:"",
      access:{products:false,courses:false,system:false}
    }
  });
  
  return (
    <AuthContext.Provider value={{ username, setUsername, password,setPassword, access, setAccess }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
