import React, {useRef, useEffect, useState} from 'react'
import './Navbar.css'
import navData from './NavbarData'
import LoginBtns from './LoginBtns'
import AccountPartial from './AccountPartial'
import {BsChevronDown, BsChevronUp, BsList, BsX } from 'react-icons/bs'
import {Link, useLocation} from 'react-router-dom'
import useUser from '../components/UserDataProvider'
import axios from 'axios'
import url from '../Route'
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
function Navbar() {
    const location = useLocation()
    const {t, i18n} = useTranslation()
    const navRef = useRef(null)
    const [itemsListLen, setItemsListLen] = useState(2)
    const dropdownRef = useRef(null)
    const [currentLang, setCurrentLang] = useState('en')
    const [active, setActive] = useState(false)
    const [isLogout, setIsLogout] = useState(false)
    const [isLangHover, setIsLangHover] = useState(true)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [initialHover, setInitialHover] = useState({
        width:40,
        stepIndex:0
    })
    const user = useUser()
    const [currentUser, setCurrentUser] = useState({})
    const accountDropdown = useRef(null)
    useEffect(() => {
        const list = navRef.current
        const navBtns = list.querySelectorAll(".btn")
        const width = navBtns[0].clientWidth
        setInitialHover((prevInitialHover) => {return{...prevInitialHover, width}})
        if (user) {
            setCurrentUser(user.data)
          }
    }, [user])

    useEffect(() => {
        if(currentLang  === 'en'){
            setInitialHover(() => {return {
                width:40,
                stepIndex:0
            }})
        }else {
            setInitialHover(() => {return {
                width:100,
                stepIndex:0
            }})
        }
    }, [currentLang])
    const logout = async () => {
        await axios.post(url + '/api/user/logout')
                    .then(res => {
                        console.log(res)
                        window.location.reload(); 
                    })
                    .catch(err => {
                        console.log(err)
                    })
    }
  return (
    <nav dir='ltr' className={location.pathname === '/polaxis-polarization-filter' ? 'dark' : ''}>
        <div className={active || isLogout ? 'shadow active' : 'shadow'} onClick={() => {
            setActive(false)
            setIsLogout(false)
        }}></div>
        {
            currentUser ? (
                <div className={isLogout ? 'logout-message active' : 'logout-message'}>
                    <h2>{t("logout")}</h2>
                    <p className='paragraph'>{t("logoutWarning")}</p>
                    <div className='btns-container'>
                        <button className='log-out' onClick={logout}>{t("logout")}</button>
                        <button onClick={() => {setIsLogout(false)}}>{t("cancel")}</button>
                    </div>
                </div>
            ) : <></>
        }
        <div className='container'>
            <div className='brand'>
                <div className='image'>
                    <img src={require("../assets/images/logo.png")} alt='logo' />
                </div>
                <div className='name'>
                    <h2>Dental<span>Point</span></h2>
                </div>
            </div>
            <div className={active ? 'nav-btns active' : 'nav-btns'}>
                <p className='sidebar-btns close' onClick={() => {
                    setActive(false)
                }}>
                    <BsX />
                </p>
                <ul ref={navRef}>
                    <li className={`hover step-${initialHover.stepIndex}`} style={{width:initialHover.width < 250 ? initialHover.width + 'px' : 40}}>.</li>
                    <li className={`hover step-${initialHover.stepIndex}`} style={{width:initialHover.width < 250 ? initialHover.width + 'px' : 40}} id='hover-2'>.</li>
                    {
                        navData.map((btn, index) => {
                            return (
                                <li key={index} className='btn' id={btn.title.toLowerCase()} onClick={() => {
                                    if(btn.hasDropdown){
                                        setIsCollapsed(!isCollapsed)
                                    }else {
                                        setActive(false)
                                    }
                                }}>
                                    <div className='content'>
                                        <Link to={btn.hasDropdown ? location.pathname : btn.path} className='btns-links' onMouseOver={(e) => {
                                            const width = e.target.clientWidth
                                            const stepIndex = index
                                            setInitialHover({width, stepIndex})
                                }}  >
                                        <span>
                                            {btn.icon}
                                        </span>
                                        {t(btn.title)}
                                        {
                                            btn.hasDropdown ? (
                                                <div className={isCollapsed ? 'collapse-btn active' : 'collapse-btn'}>
                                                    <BsChevronUp />
                                                </div>
                                            ) : <></>
                                        }
                                        </Link>
                                        {
                                            btn.hasDropdown ? (
                                                <div className={isCollapsed ? 'dropdown active' : 'dropdown'}>
                                                    <ul>
                                                        <li onClick={() => {setActive(false)}}>
                                                            <Link to='/products'>
                                                                Products
                                                            </Link>
                                                        </li>
                                                        <li onClick={() => {setActive(false)}}>
                                                            <Link to='/polaxis-polarization-filter'>
                                                                Polaxis Polarization Filter
                                                            </Link>
                                                        </li>
                                                        <li onClick={() => {setActive(false)}}>
                                                            <Link to='/courses'>
                                                                Dental Courses
                                                            </Link>
                                                        </li>
                                                        <li onClick={() => {setActive(false)}}>
                                                            <Link to='/services?data=marketing'>
                                                                Marketing Services
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : ""
                                        }
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='options'>
                <div className='account'>
                    {
                        !currentUser ? <LoginBtns 
                        login={t("login")} 
                        signup={t("signup")} 
                        /> : <AccountPartial 
                        name={`${currentUser.firstName + " " + currentUser.lastName}`}
                        changeState={() => {
                            setIsLangHover(false)
                            dropdownRef.current.swiper.slideTo(0)
                            setItemsListLen(3)
                        }}/>
                    }
                    
                    <div className={isLangHover ? 'account-dropdown isLang' : 'account-dropdown'} ref={accountDropdown}>
                        <Swiper className='swiper-container' ref={dropdownRef} initialSlide={1} style={{height:itemsListLen * 41}}>
                            {
                                currentUser ? (
                                    <SwiperSlide>
                            <ul>
                                <li>
                                    <Link to='/account/cart'>
                                            {t("cart")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/account/courses'>
                                            {t("courses")}
                                    </Link>
                                </li>
                                <li id='log-out' onClick={() => {
                                    setIsLogout(true)
                                }}>
                                    {t("logout")}
                                </li>
                            </ul>
                            </SwiperSlide> 
                                ) : <></>
                            }
                       </Swiper>

                    </div>
                    
                </div>
                <p className='sidebar-btns open' onClick={() => {
                    setActive(true)
                }}>
                    <BsList />
                </p>
            </div>
        </div>
    </nav>
  )
}

export default Navbar
