import React from 'react'
import './ResourceDownloads.css'

function ResourceDownloads() {
  return (
    <main id='resource-downloads'>
        <div className='container'>
            <div className='resource'>
                <img src={require("../assets/images/p1.png")} alt='resource' />
                <div className='desc'>
                    <h3>Resource Title</h3>
                    <p>Lorem ipsum dolor sit amet.</p>
                    <div className='btns-container'>
                        <button>Download</button>
                        <button>Download</button>
                    </div>
                </div>
            </div>
            <div className='resource'>
                <img src={require("../assets/images/p1.png")} alt='resource' />
                <div className='desc'>
                    <h3>Resource Title</h3>
                    <p>Lorem ipsum dolor sit amet.</p>
                    <div className='btns-container'>
                        <button>Download</button>
                        <button>Download</button>
                    </div>
                </div>
            </div>
            <div className='resource'>
                <img src={require("../assets/images/p1.png")} alt='resource' />
                <div className='desc'>
                    <h3>Resource Title</h3>
                    <p>Lorem ipsum dolor sit amet.</p>
                    <div className='btns-container'>
                        <button>Download</button>
                        <button>Download</button>
                    </div>
                </div>
            </div>
            <div className='resource'>
                <img src={require("../assets/images/p1.png")} alt='resource' />
                <div className='desc'>
                    <h3>Resource Title</h3>
                    <p>Lorem ipsum dolor sit amet.</p>
                    <div className='btns-container'>
                        <button>Download</button>
                        <button>Download</button>
                    </div>
                </div>
            </div>
            <div className='resource'>
                <img src={require("../assets/images/p1.png")} alt='resource' />
                <div className='desc'>
                    <h3>Resource Title</h3>
                    <p>Lorem ipsum dolor sit amet.</p>
                    <div className='btns-container'>
                        <button>Download</button>
                        <button>Download</button>
                    </div>
                </div>
            </div>
            <div className='resource'>
                <img src={require("../assets/images/p1.png")} alt='resource' />
                <div className='desc'>
                    <h3>Resource Title</h3>
                    <p>Lorem ipsum dolor sit amet.</p>
                    <div className='btns-container'>
                        <button>Download</button>
                        <button>Download</button>
                    </div>
                </div>
            </div>
            
        </div>
    </main>
  )
}

export default ResourceDownloads