import React from 'react'
import { Link } from 'react-router-dom'

function LoginBtns(props) {
  return (
    <>
        <Link to='login'>
        <div className='login-btns'>
            <button>{props.login}</button>
            <button>{props.login}</button>
        </div>
        </Link>
        <Link to='/signup'>
          <div className='signup-btn'>
              <button>{props.signup}</button>
          </div>
        </Link>
    </>
  )
}

export default LoginBtns