import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import url from '../Route'

const UserContext = createContext();

function useUser() {
  return useContext(UserContext);
}

export function UserDataProvider({ children }) {
  const [user, setUser] = useState({});

  useEffect(() => {
     const fetchData = async () => {
      try {
        const response = await fetch(url + '/api/user/get-user', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if(!data){setUser({data:null})}else{console.log('Fetched Data:', data);

        // Set the fetched data in the state
        setUser({data});}
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <UserContext.Provider value={user}>{children}</UserContext.Provider>
  );
}

export default useUser;
