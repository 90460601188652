import React from 'react'
import './Footer.css'
import {Link, useLocation} from 'react-router-dom'

function Footer() {
  const location = useLocation()
  return (
    <footer className={location.pathname === '/polaxis-polarization-filter' ? 'dark' : ''} >
      <div className='container'>
        <div>
          <h3>Quick <span>Links</span></h3>
          <ul>
            <li>
              <Link to='/faq'>
                FAQs
              </Link>
            </li>
            <li>
              <Link to='/'>
                Privacy Policy & Terms Of Use
              </Link>
            </li>
            <li>
              <Link to='/resource-downloads'>
                Resource Downloads
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h3><span>Socials</span></h3>
          <ul>
            <li>
              <a href='https://instagram.com'>
                Instagram
              </a>
            </li>
            <li>
              <a href='https://instagram.com'>
                Facebook
              </a>
            </li>
            <li>
              <a href='https://instagram.com'>
                Twitter
              </a>
            </li>
            <li>
              <a href='https://instagram.com'>
                Email
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='copyright'>
        <p>&copy;{new Date().getFullYear()}<span> DentalPoint</span> - All Rights Reserved</p>
      </div>
    </footer>
  )
}

export default Footer