import React from 'react'
import './Loading.css'
function Loading() {
  return (
    <div id='loading'>
        <div className='circles'></div>
        <div className='circles'></div>
    </div>
  )
}

export default Loading